import { Container, Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { NS_COMMON, NS_HOME } from 'constants/i18n';
import { useRouteData } from 'context/RouteDataProvider';
import type { Review } from 'types/content';
import { Section } from './Section';
import { VideoReviewCard } from './VideoReviewCard';
import styles from './VideoReviewSection.module.scss';

type VideoReviewSectionProps = {
  videos?: Review[];
};

const VideoReviewSection = ({ videos }: VideoReviewSectionProps) => {
  const { reviews: routeReviews = [] } = useRouteData();
  const { t } = useTranslation([NS_COMMON, NS_HOME]);

  const reviews = videos || routeReviews;

  if (!reviews.length) {
    return null;
  }

  return (
    <Section>
      <Container>
        <Stack crossAxisAlign="stretch" gap={{ mobileUp: 5, tabletLandscapeUp: 8 }}>
          <Stack gap={2}>
            <Heading as="h2" variant="l">
              {t('testimonialsSection.title')}
            </Heading>

            <Text variant="l">{t('home:testimonialsSection.subtitle')}</Text>
          </Stack>

          <div className={styles.cards}>
            {reviews.map(({ excerpt, photo: { dimensions, url }, youtubeId }) => (
              <VideoReviewCard
                key={youtubeId}
                className={styles.card}
                thumbnail={{
                  url,
                  width: dimensions.width,
                  height: dimensions.height,
                }}
                youtubeId={youtubeId}
                excerpt={excerpt}
              />
            ))}
          </div>
        </Stack>
      </Container>
    </Section>
  );
};

export { VideoReviewSection };
export type { VideoReviewSectionProps };
