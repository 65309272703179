import PropTypes from 'prop-types';
import cx from 'classnames';
import Image from 'next/image';
import { forClient } from 'utils/ssr';
import styles from './AspectRatioImage.module.scss';

// We only want to render the print-friendly image for headless Chrome that
// we’re using for generating report PDFs.
const headless = forClient(() => /\bHeadlessChrome\b/.test(navigator.userAgent));

const AspectRatioImage = ({
  className,
  aspectRatioClassName,
  imageClassName,
  ratio,
  src,
  printSrc,
  alt,
  unoptimized,
  ...props
}) => (
  <div className={cx(styles.root, className)} {...props}>
    <div
      className={cx(styles.aspectRatio, aspectRatioClassName)}
      style={{ paddingBottom: `${Number((1 / ratio) * 100).toFixed(2)}%` }}
    >
      {src && (
        <>
          <span className={styles.imageWrapper}>
            <Image
              className={cx(styles.image, imageClassName)}
              src={src}
              fill
              sizes="100vw"
              alt={alt}
              unoptimized={unoptimized}
            />
          </span>
          {printSrc && headless && (
            <span className={cx(styles.imageWrapper, styles.printWrapper)}>
              <Image
                className={cx(styles.image, imageClassName)}
                src={printSrc}
                loading="eager"
                fill
                sizes="100vw"
                alt={alt}
                unoptimized={unoptimized}
              />
            </span>
          )}
        </>
      )}
    </div>
  </div>
);

AspectRatioImage.propTypes = {
  className: PropTypes.string,
  aspectRatioClassName: PropTypes.string,
  imageClassName: PropTypes.string,
  ratio: PropTypes.number,
  src: PropTypes.string,
  printSrc: PropTypes.string,
  alt: PropTypes.string,
  unoptimized: PropTypes.bool,
};
AspectRatioImage.defaultProps = {
  className: undefined,
  aspectRatioClassName: undefined,
  imageClassName: undefined,
  ratio: 1,
  src: '',
  printSrc: '',
  alt: '',
  unoptimized: false,
};

export default AspectRatioImage;
