import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { Icon, Stack, Text } from '@carvertical/ui';
import { NS_HOME } from 'constants/i18n';
import { VideoThumbnail } from 'components/common/VideoThumbnail';
import Video from 'components/common/Video';
import { videoBaseData } from 'utils/youtube';
import styles from './VideoReviewCard.module.scss';

type Thumbnail = {
  url: string;
  width: number;
  height: number;
};

type VideoReviewCardProps = {
  youtubeId: string;
  excerpt: string;
  thumbnail: Thumbnail;
  author?: string;
  className?: string;
};

const MAX_WIDTH = 1200;

const VideoReviewCard = ({
  author,
  className,
  excerpt,
  thumbnail,
  youtubeId,
}: VideoReviewCardProps) => {
  const { t } = useTranslation(NS_HOME);
  const { excerptId, id, start } = videoBaseData(youtubeId);

  return (
    <article className={cx(styles.root, className)}>
      <Video
        id={id || youtubeId}
        maxWidth={MAX_WIDTH}
        // eslint-disable-next-line react/no-unstable-nested-components
        thumbnail={(play: () => void) => (
          <div className={styles.wrapper}>
            <div className={styles.imageWrapper}>
              <VideoThumbnail src={thumbnail.url} play={play} className={styles.thumbnail} />
            </div>

            <Stack gap={1} className={styles.content}>
              <Text as="p" variant="l+">
                {excerpt}
              </Text>

              {author && (
                <Text as="strong" variant="m+">
                  {author}
                </Text>
              )}

              <button
                type="button"
                onClick={play}
                aria-labelledby={excerptId}
                className={styles.button}
              >
                <Stack type="horizontal" crossAxisAlign="center" gap={1}>
                  <Icon name="youtube" className={styles.youtube} />

                  <Text variant="s">{t('testimonialsSection.youtubeAction')}</Text>
                </Stack>
              </button>
            </Stack>
          </div>
        )}
        start={start}
        playedInModal
      />
    </article>
  );
};

export { VideoReviewCard };
export type { VideoReviewCardProps };
