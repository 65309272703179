import { useRouteData } from 'context/RouteDataProvider';
import { getExperiment } from 'services/experiments';
import { isExistingMarket } from 'utils/market';

const HOME_EXPERIMENT = 'homeRew';
const PRECHECK_EXPERIMENT = 'preRew';
const V3_LANDING_EXPERIMENT = 'v3Rew';
const ADW_LANDING_EXPERIMENT = 'adwRew';

const useUserReviewsExperiment = () => {
  const { market, locale } = useRouteData();

  const v3LandingMarket = isExistingMarket({
    market,
    markets: ['britain', 'poland', 'romania'],
    locale,
  });

  const experimentMarket = isExistingMarket({
    market,
    markets: ['france', 'italy', 'britain', 'poland', 'romania', 'europe'],
    locale,
  });

  return {
    adwEnabled: getExperiment(ADW_LANDING_EXPERIMENT) === 'v1' && experimentMarket,
    v3Enabled: getExperiment(V3_LANDING_EXPERIMENT) === 'v1' && v3LandingMarket,
    precheckEnabled: getExperiment(PRECHECK_EXPERIMENT) === 'v1' && experimentMarket,
    homeEnabled: getExperiment(HOME_EXPERIMENT) === 'v1' && experimentMarket,
  };
};

export { useUserReviewsExperiment };
