const VIDEO_ID_START_REGEX = /^(\w+)\?t=(\d+)$/;

const videoBaseData = (youtubeId: string) => {
  const [id, start] = youtubeId.match(VIDEO_ID_START_REGEX)?.slice(1) ?? [youtubeId];

  return {
    id,
    start: start ? Number(start) : undefined,
    excerptId: `excerpt-${youtubeId}`,
  };
};

export { videoBaseData };
