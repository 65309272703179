import cx from 'classnames';
import { Container, Heading, Stack, Text, type TextProps } from '@carvertical/ui';
import type React from 'react';
import { FeatureCheckList } from 'components/common/FeatureCheckList';
import { IdentifierForm } from 'components/common/IdentifierForm';
import { Section } from 'components/landings/common/Section';
import type { SurroundingBackground } from 'components/common/IdentifierForm/types';
import type { ThemeColor } from 'types/color';
import styles from './Hero.module.scss';

type HeroProps = {
  id?: string;
  leftContent: {
    title: string | React.ReactNode;
    subtitle: string | React.ReactNode;
    identifier?: React.ReactNode;
    additionalContent?: React.ReactNode;
    className?: string;
    textShown?: boolean;
  };
  rightContent: {
    illustration: React.ReactNode;
    className?: string;
    illustrationClassName?: string;
  };
  className?: string;
  color?: ThemeColor;
  textColor?: TextProps['textColor'];
  vertical?: boolean;
};

const SURROUNDING_COLOR_MAP: Partial<Record<ThemeColor, SurroundingBackground>> = {
  colorBlue50: 'light',
  colorBlue100: 'mediumDark',
  colorBlue200: 'mediumDark',
  colorBlue: 'dark',
};

const Hero = ({
  id,
  className,
  color = 'colorWhite',
  leftContent: {
    additionalContent = <FeatureCheckList />,
    className: leftContentClassName,
    identifier = <IdentifierForm surroundingBackground={SURROUNDING_COLOR_MAP[color]} />,
    subtitle,
    title,
    textShown = true,
  },
  rightContent: {
    className: rightContentClassName,
    illustrationClassName: rightContentIllustrationClassName,
    illustration,
  },
  textColor = 'dark',
  vertical,
}: HeroProps) => (
  <Section
    color={color}
    className={cx(styles.root, className, vertical && styles.vertical)}
    id={id}
  >
    <Container>
      <div className={styles.container}>
        <Stack
          gap={{ mobileUp: 3, desktopUp: 4.5 }}
          className={cx(styles.leftContent, styles.centered, leftContentClassName)}
        >
          <Stack
            gap={{ mobileUp: 3, desktopUp: 4 }}
            className={cx(styles.titleWrapper, styles.centered)}
          >
            <Stack
              gap={{ mobileUp: 1, desktopUp: 2 }}
              className={cx(styles.centered, !textShown && 'sr-only')}
            >
              <Heading
                align={vertical ? 'center' : undefined}
                as="h1"
                variant={vertical ? 'l' : '2xl'}
                textColor={textColor}
              >
                {title}
              </Heading>
              <Text
                align={vertical ? 'center' : undefined}
                variant="l"
                className={styles.subtitle}
                textColor={textColor}
              >
                {subtitle}
              </Text>
            </Stack>

            {identifier}
          </Stack>

          {additionalContent}
        </Stack>

        <Stack
          gap={0}
          crossAxisAlign={vertical ? 'center' : 'stretch'}
          className={cx(styles.rightContent, rightContentClassName)}
        >
          <div className={cx(styles.imageWrapper, rightContentIllustrationClassName)}>
            {illustration}
          </div>
        </Stack>
      </div>
    </Container>
  </Section>
);

export { Hero };
export type { HeroProps };
